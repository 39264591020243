<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="justify-left">
      <div v-if="props.item.result" style="padding: 3px 8px; color: white; font-size: 18px;" :style="{ background: props.item.result.color }">
        <v-icon color="white">{{ props.item.result.icon }}</v-icon>
        <strong> {{ props.item.result.name || '- - -' }}</strong>
      </div>
    </td>
    <td class="justify-left">
      <strong>{{ props.item.result_stage.name || '- - -' }}</strong><br>
      <v-icon small>{{ props.item.result_stage.type.icon || '- - -' }}</v-icon>
      <span style="padding-left: 5px;">{{ props.item.result_stage.type.name || '- - -' }}</span>
    </td>
    <td class="justify-left" style="font-size: 20px;">
      <strong>{{ props.item.result_stage.order || '- - -' }}</strong>
    </td>
    <td class="justify-left" style="font-size: 20px;">
      <strong style="border-style: solid; border-width: 1px; padding: 2px;">
        <v-icon>unfold_more</v-icon>
        <span> {{ props.item.order || '- - -' }}</span>
      </strong>
    </td>
    <td class="justify-left" style="font-size: 20px;">
      <strong>{{ props.item.count || '- - -' }}</strong>
    </td>
  </tr>
</template>
<script>
  import processMixin from '@/modules/service-desk/pages/Process/processMixin'
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'ProcessStageResults',
    methods: {
      listResults: function () {
        this.$WiListDialog({
          wiConfig: 'process-stage-result',
          filter: {
            where: 'process_stage_id,' + this.props.item.id
          },
          wiEditData: {
            process_stage_id: this.props.item.id
          }
        })
      }
    },
    props: {
      config: Object,
      props: Object
    },
    mixins: [
      processMixin
    ],
    components: {
      WiListDataTableActions
    }
  }
</script>
<style>
  .process__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .process__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .process__status--inactive {
    color: grey;
    background: #eceff1;
  }
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
</style>